<template>
	<div class="tabs">
		<h3><span>案例介绍</span></h3>
		<div class="tabs_box">
			<ul class="tab_inner">
				<li @click="settabindex(0)"  :class="[tabs==0?'active':'']">运营商</li>
				<li @click="settabindex(1)"  :class="[tabs==1?'active':'']">政府行业</li>
				<li @click="settabindex(2)"  :class="[tabs==2?'active':'']">教育行业</li>
				<li @click="settabindex(3)"  :class="[tabs==3?'active':'']">交通行业</li>
				<li @click="settabindex(4)"  :class="[tabs==4?'active':'']">电商行业</li>
				<li @click="settabindex(5)"  :class="[tabs==5?'active':'']">电力行业</li>
				<li @click="settabindex(6)"  :class="[tabs==6?'active':'']">物流行业</li>
				<li @click="settabindex(7)"  :class="[tabs==7?'active':'']">金融行业</li>
				<li @click="settabindex(8)"  :class="[tabs==8?'active':'']">医疗行业</li>
				<li @click="settabindex(9)"  :class="[tabs==9?'active':'']">地产行业</li>
				<li @click="settabindex(10)"  :class="[tabs==10?'active':'']">影视行业</li>
				<li @click="settabindex(11)"  :class="[tabs==11?'active':'']">游戏行业</li>
				<li @click="settabindex(12)"  :class="[tabs==12?'active':'']">互联网行业</li>
				<li @click="settabindex(13)"  :class="[tabs==13?'active':'']">其他</li>
			</ul>
			<ul class="show_img_box">
				<li v-if="tabs==0">
					<img  src="@/assets/images/case/a01.png">
					<img  src="@/assets/images/case/a02.png">
				</li>
				<li  v-if="tabs==1">
				   <img  src="@/assets/images/case/b01.jpg">
					<img  src="@/assets/images/case/b02.jpg">
					<img  src="@/assets/images/case/b03.jpg">
					<img  src="@/assets/images/case/b04.jpg">
					<img  src="@/assets/images/case/b05.jpg">
					<img  src="@/assets/images/case/b06.jpg">
					<img  src="@/assets/images/case/b07.jpg">
					<img  src="@/assets/images/case/b08.jpg">
				</li>
				<li  v-if="tabs==2">
					<img  src="@/assets/images/case/c01.png">
					<img  src="@/assets/images/case/c02.png">
					<img  src="@/assets/images/case/c03.png">
					<img  src="@/assets/images/case/c04.png">
					<img  src="@/assets/images/case/c05.jpg">
					<img  src="@/assets/images/case/c06.jpg">
					<img  src="@/assets/images/case/c07.jpg">
					<img  src="@/assets/images/case/c08.jpg">
					<img  src="@/assets/images/case/c09.jpg">
					<img  src="@/assets/images/case/c10.jpg">
					<img  src="@/assets/images/case/c11.jpg">
					<img  src="@/assets/images/case/c12.jpg">
				</li>
				<li v-if="tabs==3">
					<img  src="@/assets/images/case/d01.png">
					<img  src="@/assets/images/case/d02.jpg">
					<img  src="@/assets/images/case/d03.jpg">
					<img  src="@/assets/images/case/d04.jpg">
					<img  src="@/assets/images/case/d05.jpg">
					<img  src="@/assets/images/case/d06.jpg">
					<img  src="@/assets/images/case/d07.jpg">
					<img  src="@/assets/images/case/d08.jpg">
					<img  src="@/assets/images/case/d09.jpg">
					<img  src="@/assets/images/case/d10.jpg">
					<img  src="@/assets/images/case/d11.jpg">
				</li>
				<li v-if="tabs==4">
					<img  src="@/assets/images/case/e01.png">
					<img  src="@/assets/images/case/e02.png">
					<img  src="@/assets/images/case/e03.jpg">
					<img  src="@/assets/images/case/e04.jpg">
					<img  src="@/assets/images/case/e05.jpg">
					<img  src="@/assets/images/case/e06.jpg">
					<img  src="@/assets/images/case/e07.jpg">
					<img  src="@/assets/images/case/e08.jpg">
					<img  src="@/assets/images/case/e09.jpg">
					<img  src="@/assets/images/case/e10.jpg">
					<img  src="@/assets/images/case/e11.jpg">
					<img  src="@/assets/images/case/e12.jpg">
					<img  src="@/assets/images/case/e13.jpg">
					<img  src="@/assets/images/case/e14.jpg">
					<img  src="@/assets/images/case/e15.jpg">
					<img  src="@/assets/images/case/e16.jpg">
					<img  src="@/assets/images/case/e17.jpg">
					<img  src="@/assets/images/case/e18.jpg">
					<img  src="@/assets/images/case/e19.jpg">
				</li>
				<li v-if="tabs==5">
					<img  src="@/assets/images/case/f01.png">
					<img  src="@/assets/images/case/f02.jpg">
					<img  src="@/assets/images/case/f03.jpg">
					<img  src="@/assets/images/case/f04.jpg">
					<img  src="@/assets/images/case/f05.jpg">
					<img  src="@/assets/images/case/f06.jpg">
				</li>
				<li v-if="tabs==6">
					<img  src="@/assets/images/case/g01.png">
					<img  src="@/assets/images/case/g02.jpg">
					<img  src="@/assets/images/case/g03.jpg">
					<img  src="@/assets/images/case/g04.jpg">
					<img  src="@/assets/images/case/g05.jpg">
					<img  src="@/assets/images/case/g06.jpg">
					<img  src="@/assets/images/case/g07.jpg">
					<img  src="@/assets/images/case/g08.jpg">
					<img  src="@/assets/images/case/g09.jpg">
					<img  src="@/assets/images/case/g10.jpg">
					<img  src="@/assets/images/case/g11.jpg">
				</li>
				<li v-if="tabs==7">
					<img  src="@/assets/images/case/h01.png">
					<img  src="@/assets/images/case/h02.png">
					<img  src="@/assets/images/case/h03.jpg">
					<img  src="@/assets/images/case/h04.png">
				</li>
				<li v-if="tabs==8">
					<img  src="@/assets/images/case/i01.png">
					<img  src="@/assets/images/case/i02.jpg">
					<img  src="@/assets/images/case/i03.jpg">
					<img  src="@/assets/images/case/i04.jpg">
					<img  src="@/assets/images/case/i05.jpg">
					<img  src="@/assets/images/case/i06.jpg">
				</li>
				<li v-if="tabs==9">
					<img  src="@/assets/images/case/j01.png">
					<img  src="@/assets/images/case/j02.jpg">
					<img  src="@/assets/images/case/j03.jpg">
					<img  src="@/assets/images/case/j04.jpg">
				</li>
				<li v-if="tabs==10">
					<img  src="@/assets/images/case/k01.jpg">
					<img  src="@/assets/images/case/k02.jpg">
					<img  src="@/assets/images/case/k03.jpg">
					<img  src="@/assets/images/case/k04.jpg">
				</li>
				<li v-if="tabs==11">
					<img  src="@/assets/images/case/l01.jpg">
					<img  src="@/assets/images/case/l02.jpg">
					<img  src="@/assets/images/case/l03.jpg">
				
				</li>
				<li v-if="tabs==12">
					<img  src="@/assets/images/case/m01.jpg">
					<img  src="@/assets/images/case/m02.jpg">				
				</li>
				<li v-if="tabs==13">
					<img  src="@/assets/images/case/n01.jpg">
					<img  src="@/assets/images/case/n02.jpg">				
					<img  src="@/assets/images/case/n03.jpg">				
					<img  src="@/assets/images/case/n04.jpg">				
					<img  src="@/assets/images/case/n05.jpg">				
					<img  src="@/assets/images/case/n06.jpg">				
					<img  src="@/assets/images/case/n07.jpg">				
					<img  src="@/assets/images/case/n08.jpg">				
					<img  src="@/assets/images/case/n09.jpg">				
					<img  src="@/assets/images/case/n10.jpg">				
					<img  src="@/assets/images/case/n11.jpg">				
					<img  src="@/assets/images/case/n12.jpg">				
					<img  src="@/assets/images/case/n13.jpg">				
					<img  src="@/assets/images/case/n14.jpg">				
					<img  src="@/assets/images/case/n15.jpg">				
				</li>
			</ul>
			
			</div>
			<div class="tui">
				<h6><span>推荐产品</span></h6>
				<ul class="recommandList">
		          <li>
		            <router-link to="/details" target="_blank" class="prodLink">
		            <div class="recommandLeft">
		              <img src="@/assets/images/b01.jpg" alt="">
		            </div>
		            <div class="recommandRight">
		              <h5 id="prodName">网络安全等级保护测评服务</h5>
		              <!-- <p>网络安全等级保护测评服务</p>
		              <i>￥</i><span>40000</span> <b style="color:#888">/年起</b> -->
		            </div>
		            </router-link>
		          </li>
		          <li>
		            <router-link to="/icpedi" target="_blank" class="prodLink">
		            <div class="recommandLeft">
		              <img src="@/assets/images/icpedi/2.png" alt="">
		            </div>
		            <div class="recommandRight">
		              <h5 id="prodName">ICP/EDI许可证第三方风险评估</h5>
		             <!--  <p>ICP/EDI许可证第三方风险评估</p>
		              <i>￥</i><span>0.00</span> -->
		            </div>
		            </router-link>
		          </li>
		          <li>
		            <router-link to="/dengbaohegui" target="_blank" class="prodLink">
		            <div class="recommandLeft">
		              <img src="@/assets/images/dengbaohegui/3.png" alt="">
		            </div>
		            <div class="recommandRight">
		              <h5 id="prodName">等保合规套餐</h5>
		            <!--   <p>等保合规套餐</p> 
		              <i>￥</i><span>0.00</span> -->
		            </div>
		            </router-link>
		          </li>
		          <li>
		            <router-link to="/yunsafe" target="_blank" class="prodLink">
		            <div class="recommandLeft">
		              <img src="@/assets/images/yunsafe/222.jpg" alt="">
		            </div>
		            <div class="recommandRight">
		              <h5 id="prodName">云上业务安全无忧保障计划初级套餐</h5>
		             <!--  <p>云上业务安全无忧保障计划初级套餐</p> 
		              <i>￥</i><span>0.00</span> -->
		            </div>
		            </router-link>
		          </li>
		        </ul>

		</div>
	</div>
 
</template>
<script>
  export default {
    data() {
      return {
        tabs: 0
      };
    },
    methods: {
      settabindex(val){
      this.tabs=val;
      }
    }
  };
</script>

<style type="text/css" scoped lang="less">
.tabs{

h3{
margin: 0px auto;
font-size: 28px;
text-shadow: none;
color: #333;
width:1200px ;
text-align: center;
padding: 30px 0;
background:#fff;
margin-top:20px;
}
h3 span{
	padding:5px;
	padding-bottom:8px;
	border-bottom:3px solid  #62a8ea;
}
.tabs_box{
 width:1200px;
 margin:0 auto;
 padding:0 10px;
 box-sizing:border-box;
 background: #fff;
}
.tab_inner{
	overflow: hidden;
	border-bottom:1px solid #f0f2f5;
	padding:10px 0;
	text-align:center;
}
.tab_inner li{
	display: inline-block;
	padding: 5px 10px;
    font-size: 16px;
    color:#292e33;
}
.tab_inner li:hover{
	color:#62a8ea;
	cursor: pointer;
}
.tab_inner li.active{
	color:#62a8ea;
}
.show_img_box{
	width:1000px;
	margin:0 auto;
}
.show_img_box li{
	text-align:center;
	min-height:250px;
}
.show_img_box li p{
	padding:30px 0;
	font-size:15px;
}
.show_img_box li img{
  width:300px;
  height:100px;
  color:#666;
  margin:10px 3px;
}
.show_img_box li img:hover{
	background-color: #fff;
	background-position-y: -240px;
    box-shadow: 0 2px 5px 1px #edeaea, 0 0 5px 1px #edeaea, 0 0 5px 1px #edeaea;
}
.tui {
	margin:20px auto;
	width:1200px;
}
.tui h6{
	text-align:center;
	font-size:24px;
	color:#333;
	padding:20px 0;
    background:#fff;
}
.tui h6 span{
	padding:5px;
	padding-bottom:8px;
	border-bottom:3px solid  #62a8ea;
}
.recommandList{
	 width: 100%;
	 margin:0 auto;
	 padding:0 10%;
    // padding:4px 20px;
    overflow: hidden;
    background:#fff;
    box-sizing: border-box;
    padding-bottom:30px;
}
.recommandList li{
   // display: flex;
    float:left;
    width:25%;
    margin-top: 25px;
    cursor: pointer;
}
.recommandLeft{
  width:90%;
  // height: 70px;
  margin:0 2%;
  background-color: skyblue;
  display: inline-block;
  float: left;
}
.recommandLeft>img{
	width:100%;
	// height:70px;
	// margin-right:3px;
}
.recommandRight{
	display: inline-block;
  width: 90%;
  padding: 0;
  height: 100%;
  padding-left:3px;
  box-sizing:border-box;
  float: left;
}
.recommandRight>h5 {
  font-size: 15px;
  color: #555;
  line-height:28px;
  padding-left:3px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
.recommandRight p {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    padding: 0;
    margin: 5px 0 0;
    overflow: hidden;
    line-height: 25px;
    margin-bottom:5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
 }
.recommandRight span {
    font-size: 12px;
    font-weight: 400;
    color: #FF6A00;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
}
.recommandRight>i{
    font-size: 12px;
    font-weight: 400;
    color: #FF6A00;
}




}
</style>